
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {AUTH_STORE_NAME} from "@/store/auth.store";
import {namespace} from "vuex-class";
import {APPLICATION_STORE_NAME} from "@/store/application.store";

import PaymentMethod from "@/models/PaymentMethod";
import {PaymentType} from "@/enum/PaymentType.enum";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component
export default class ConnectedPaymentListItemComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private paymentMethod!: PaymentMethod;

  @Prop({required: true})
  private onDeletePaymentClicked!: (method: PaymentMethod) => Promise<void>;

  @Prop({required: true})
  private onSetPaymentAsDefaultClicked!: (method: PaymentMethod) => void;

  @Prop({default: false})
  private disableDeletingPaypal!: boolean;

  /**
   * checks if the payment method is of type credit card
   * @param method
   * @private
   */
  private isCardMethod(method: PaymentMethod) {
    if(this.isGooglePay(method)) return false;
    return method.type === PaymentType.CREDIT_CARD;
  }

  private capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  /**
   * returns the card date row
   * @private
   */
  private get cardDateRow() {
    const month = ("0" + this.paymentMethod.card.expMonth).slice(-2);
    const year = this.paymentMethod.card.expYear.toString().slice(-2);
    return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.CREDIT_CARD_ROW', {
      month, year
    });
  }

  /**
   * checks if the payment method is google pay
   * @param method
   * @private
   */
  private isGooglePay(method: PaymentMethod) {
    return method.card?.wallet?.type === PaymentType.GOOGLE_PAY;
  }

  private isPaypal(method: PaymentMethod) {
    return method.type === 'paypal';
  }

  private get title(): string {
    const type = this.isGooglePay(this.paymentMethod) ? PaymentType.GOOGLE_PAY : this.paymentMethod.type;
    return this.$t(`GENERAL.PAYMENT_METHODS.${type.toUpperCase()}`).toString();
  }

  /**
   * gets the icon by the payment method type
   * @param method
   * @private
   */
  private getIconByMethod(method: PaymentMethod) {
    const tType = this.isGooglePay(method) ? PaymentType.GOOGLE_PAY : method.type;

    switch(tType) {
      case PaymentType.PAYPAL:
        return '/payment/paypal_icon.png';
      case PaymentType.GOOGLE_PAY:
        return '/payment/google_pay.png';
      default:
        return '/payment/credit_card.png';
    }
  }
}
